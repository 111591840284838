import React, { useRef, useState } from 'react'
import { Form, Button, Card, Alert, Modal } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  RecaptchaVerifier,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator
} from "firebase/auth";

import { auth } from "../../firebase.js"

function LoginForm() {
  const { login, currentUser } = useAuth();
  const history = useHistory();

  // states
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');
  const [show2FA, setShow2FA] = useState(false);
  const [verificationId, setVerificationId] = useState(null);
  const [resolver, setResolver] = useState(null);
  const [recaptchaSolved, setRecaptchaSolved] = useState(false);
  const [phoneNumberHint, setPhoneNumberHint] = useState(null);

  // refs
  const twoFARef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);
      const res = await login(emailRef.current.value, passwordRef.current.value);

      if (res == 1) {
        // If login was sucessful, redirect to main dashboard
        history.push("/");
      } else if (res == -1) {
        // -1 response indicates email is not verified
        setError("Failed to log in");
      } else {
        if (res.code === 'auth/multi-factor-auth-required') {
          setShow2FA(false);
          const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container-1', {
            size: 'invisible',
            'callback': (_) => {
              // reCAPTCHA solved, now show the modal to enter the 2FA code
              setRecaptchaSolved(true);
            }
          });
          const rsolver = getMultiFactorResolver(auth, res);
          setPhoneNumberHint(rsolver.hints[0].phoneNumber);
          const phoneInfoOptions = {
            multiFactorHint: rsolver.hints[0],
            session: rsolver.session
          };
          setResolver(rsolver);
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verId) {
              setVerificationId(verId);
            }).catch((err) => {
              alert(err);
            })

        } else if(res.code === 'auth/wrong-password') {
          setError('Invalid Email/Password Combination');
        }
      }
    } catch (err) {
      console.log(err);
      setError('Failed to log in');
    }
    setLoading(false);
  }

  const twoFaSubmit = async (e) => {
    e.preventDefault();
    const verificationCode = twoFARef.current.value;
    const cred = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    await resolver.resolveSignIn(multiFactorAssertion).catch((err) => {
      if (err.code === 'auth/invalid-verification-code') {
        alert('Incorrect verification code. You will be prompted to log in again.');
      } else {
        alert(err);
      }
    });
    setShow2FA(false);
    history.push("/");
  };

  return (
    <div>
      <Modal show={show2FA}>
        <Modal.Header>2-Factor Authentication</Modal.Header>
        <Modal.Body>
          <Alert>Enter The Verification Code Sent To Your Phone Number: {phoneNumberHint}</Alert>
          <Form onSubmit={twoFaSubmit}>
            <Form.Group>
              <Form.Control type="text" ref={twoFARef} required/>
            </Form.Group>
            <div id="recaptcha-container-1"></div>
            <div style={{marginTop: '2%'}}>
              <Button variant="outline-success" type="submit">
                Submit
              </Button>
              <Button variant="secondary" onClick={() => { setShow2FA(false) }} style={{marginLeft: '67%'}}>
                Cancel
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      <Card className="border-0" style={{ padding: "15%", marginTop: '7%', zoom: '85%' }}>
        <Card.Body>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label style={{ color: '#005E7D' }}>Email</Form.Label>
              <Form.Control type="email" ref={emailRef} onChange={(e) => { e.preventDefault() }} required />
            </Form.Group>
            <br></br>
            <Form.Group id="authentication">
              <Form.Label style={{ color: '#005E7D' }}>Password</Form.Label>
              <Form.Control type="password" ref={passwordRef} onChange={(e) => { e.preventDefault() }} required />
            </Form.Group>
            <Button disabled={loading} className="w-100 mt-2" type="submit" style={{ backgroundColor: '#005E7D' }}>Log In</Button>
          </Form>
          <div className="w-100 text-center mt-2" style={{ color: '#005E7D' }}>
            Don't have an account? <Link style={{ color: '#005E7D' }} to="/signup">Sign up</Link>
          </div>
          <div className="w-100 text-center mt-2" style={{ color: '#005E7D' }}>
            Forgot Password? <Link style={{ color: '#005E7D' }} to="/password-reset-request">Reset Password</Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default LoginForm
